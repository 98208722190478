<template>
  <div>
    <div class="box mb15">
        <el-page-header @back="$router.go(-1)"></el-page-header>
    </div>
    <el-card class="mb15" shadow="hover">
      <el-row>
        <el-input
          :placeholder="$t('search')"
          clearable
          v-model="filter.search"
          @keyup.enter.native="get_list"
        ></el-input>
      </el-row>
      <el-row class="mt20" type="flex" align="middle">
        <el-button @click="get_list" type="primary" class="search">{{
          $t("search_zh")
        }}</el-button>
        <el-button @click="reset" type="info">{{
          $t("btnTip.reset")
        }}</el-button>
      </el-row>
    </el-card>
    <el-card shadow="hover">
      <div class="flexBC mb20">
        <div></div>
        <div>
          <el-button type="primary" @click="outExe">{{ $t("outExe") }}</el-button>
        </div>
      </div>
      <my-table
        :columns="columns"
        :data="list_data.list"
        :showIndex="false"
        :showSelection="false"
        :cellStyle="{ padding: '6px 0' }"
        :headerCellStyle="{
          background: 'rgba(51, 55, 68, 1)',
          color: '#fff',
        }"
        @emitSelection="allSelect"
        @sortChange="sort_change"
      >
      <template v-slot:subject="slotProps">
          {{slotProps.callback.row.subject_info.title}}
        </template>
        <template v-slot:chapter="slotProps">
          {{slotProps.callback.row.chapter_info.title}}
        </template>
      </my-table>
      <page
        :total="list_data.total"
        :page_size.sync="pageSize"
        :page.sync="page"
      />
    </el-card>
  </div>
</template>
<script>
import { mixin_list } from "@/mixins";
import page from "@/components/page";
import myTable from "@/components/table";
import { finishHistory } from "@/api/index";
export default {
  name: "finishHistory",
  mixins: [mixin_list(finishHistory)],
  components: {
    myTable,
    page,
  },
  data() {
    return {
      filter: {
        search: "",
        user_id:this.$route.params.userId,
      },
      columns: [
        // {
        //   label: "ID",
        //   sortable: true,
        //   prop: "id",
        //   width: 80,
        // },
        {
          label: this.$t("finishHistory.create_time"),
          sortable: true,
          prop: "create_time",
        },
        {
          label: this.$t("finishHistory.subject"),
          sortable: false,
          slot:"subject",
          prop: "subject",
        },
        {
          label: this.$t("finishHistory.chapter"),
          sortable: false,
          slot:"chapter",
          prop: "chapter",
        },
        {
          label: this.$t("finishHistory.exam_count"),
          sortable: true,
          prop: "exam_count",
        },
        {
          label: this.$t("finishHistory.correct_count"),
          sortable: true,
          prop: "correct_count",
        },
        {
          label: this.$t("finishHistory.correct_rate"),
          sortable: true,
          prop: "correct_rate",
        },
        {
          label: this.$t("finishHistory.solved_count"),
          sortable: true,
          prop: "solved_count",
        },
        {
          label: this.$t("finishHistory.solved_rate"),
          sortable: true,
          prop: "solved_rate",
        },
        {
          label: this.$t("finishHistory.score"),
          sortable: true,
          prop: "score",
        },
      ],
    };
  },
  created(){
    console.log(this.$route.params.userId)
  },
  methods: {
      
  },
};
</script>
<style lang="scss" scoped>
.text{
  width:80px;
  font-size: 16px;
}
/deep/ label{
  margin-bottom:0!important
}
</style>